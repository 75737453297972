<template>
  <div>
    <div class="content">
      <div class="all">
      <div class="SearchBar">
        <el-form :inline="true" class="searchBoxForm" size="small" label-position="right">
          <el-form-item label="管线名称/编号" class="searchItem">
            <el-input
              v-model="pageParams.name"
              placeholder="请输入"
              clearable
              class="search"
            ></el-input>
          </el-form-item>
           <el-form-item label="管段名称" class="searchItem">
            <el-input
              v-model="pageParams.pipeName"
              placeholder="请输入"
              clearable
              class="search"
            ></el-input>
          </el-form-item>
          <el-form-item label="压力级别" class="searchItem">
            <el-select
                v-model="pageParams.pressureLevel"
                placeholder="请选择"
                clearable
                class="search"
                @change="search"
            >
              <el-option
                  v-for="(item, index) in pressureLevelList"
                  :key="index"
                  :label="item.name"
                  :value="item.dictCode"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="管材" class="searchItem">
            <el-select
                v-model="pageParams.pipeType"
                placeholder="请选择"
                clearable
                class="search"
                @change="pipeTypeChange"
            >
              <el-option
                  v-for="(item, index) in pipeTypeList"
                  :key="index"
                  :label="item.name"
                  :value="item.dictCode"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="公称直径" class="searchItem">
            <el-select
                v-model="pageParams.diameter"
                placeholder="请选择"
                clearable
                class="search"
                @change="search"
            >
              <el-option
                  v-for="(item, index) in diameterList"
                  :key="index"
                  :label="item.name"
                  :value="item.code"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="下次检测日期" class="searchItem">
            <el-date-picker
            clearable
              v-model="searchDate"
              type="daterange"
              value-format="yyyy-MM-dd"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期">
            </el-date-picker>
          </el-form-item>
          <el-form-item label="所属图层" class="searchItem">
              <el-cascader
                    v-model="pageParams.layerId"
                    :options="optionsList"
                    :props="props"
                    placeholder="请选择图层"
                    popper-class="gis-default"
                    style="width:100%"
                     @change="search"
                  >
                  </el-cascader>
           
          </el-form-item>
          <el-form-item label="" class="searchItem">
             <el-button type="primary" @click="search" size="mini" >查询</el-button>
             <el-button type="warning" @click="restSearch" size="mini" >重置</el-button>
             <el-button type="success" @click="exportFile" size="mini" >导出</el-button>
          </el-form-item>
        </el-form>
      </div>
    </div>
    <div class="contain">
        <el-table
            ref="tableData"
            :data="tableData"
            style="width: 100%;margin-top: 10px ;"
            border
            :header-cell-style="{
							color: '#333',
							fontFamily: 'MicrosoftYaHeiUI',
							fontSize: '14px',
							fontWeight: 900,
							textAlign: 'left',
							background: '#f8f8f9',
						}"
            height="calc(100vh - 390px)"
            :stripe="true"
          >
          <el-table-column
            type="index"
            :index="indexMethod"
            label="序号"
            align="center"
            width="60"
          >
          </el-table-column>
          <el-table-column
            prop="pipeName"
            width="180"
            label="管线名称"
            show-overflow-tooltip
          >
          </el-table-column>
          <el-table-column
            prop="pipeCode"
            label="管线编码"
            width="120"
            show-overflow-tooltip
          >
          </el-table-column>
          <el-table-column
            prop="pipeTypeName"
            label="管材"
            width="60"
            show-overflow-tooltip
          >
          </el-table-column>
          <el-table-column
            prop="pipeModelName"
            label="管材类型"
            width="100"
            show-overflow-tooltip
          >
          </el-table-column>
          <el-table-column
            prop="diameterName"
            label="公称直径"
            width="80"
            show-overflow-tooltip
          >
          </el-table-column>
          <el-table-column
            prop="pressureLevelName"
            label="压力级别"
            width="80"
            show-overflow-tooltip
          >
          </el-table-column>
          <el-table-column
            prop="gasTypeName"
            label="燃气介质"
            width="80"
            show-overflow-tooltip
          >
          </el-table-column>
          <el-table-column
            prop="buildDate"
            label="建设日期"
            width="100"
            show-overflow-tooltip
          >
          </el-table-column>
          <el-table-column
            prop="nextMonitorDate"
            label="下次检测日期"
            width="100"
            show-overflow-tooltip
          >
          </el-table-column>
          <el-table-column
            prop="road"
            label="所在道路"
            show-overflow-tooltip
          >
          </el-table-column>
          <el-table-column
            prop="remark"
            label="备注"
            show-overflow-tooltip
          >
          </el-table-column>
          <el-table-column
            prop="layerName"
            label="所属图层"
            show-overflow-tooltip
          >
          </el-table-column>
          <el-table-column
            prop="designPressure"
            label="设计压力"
            show-overflow-tooltip
          >
          </el-table-column>
          <el-table-column
            prop="operationPressure"
            label="运行压力"
            show-overflow-tooltip
          >
          </el-table-column>
          <el-table-column
            prop="buildOrganization"
            label="建设单位"
            show-overflow-tooltip
          >
          </el-table-column>
          <el-table-column
            prop="designOrganization"
            label="设计单位"
            show-overflow-tooltip
          >
          </el-table-column>
          <el-table-column label="操作" align="left" width="148">
            <template slot-scope="{row}">
              <el-button type="text" @click="getDetail(row)" size="mini">查看</el-button>
              <el-button type="text" @click="setPosition(row)" style="color: #0A8E78" size="mini">定位</el-button>
            </template> 
          </el-table-column>
        </el-table>
      <div class="total">
        <el-pagination
        background
        layout="total, prev, pager, next,sizes, jumper"
        :total="total"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page.sync="pageParams.current"
        :page-sizes="[30, 50, 100]"
        :page-size="pageParams.size"
      >
      </el-pagination>
      </div>
    </div>
    </div>
    <el-dialog
      title="查看管线"
      :visible.sync="dialogVisible"
      :inline="true"
      :modal="false"
      :close-on-click-modal="false"
      width="586px"
      custom-class="dialog-class"
      @close="close(0)"
    >
      <div class="form-box">
        <el-form
          ref="form"
          :model="form"
          :rules="rules"
          :inline="true"
          label-position="right"
          label-width="110px"
          class="form"
          size="mini"
        >
          <el-form-item label="所属图层:" prop="pipeCode">
            <el-input
              v-model="form.layerName"
              placeholder="所属图层"
              maxlength="40"
              disabled
              :style="labelWidthMax"
            ></el-input>
          </el-form-item>
          <el-form-item label="管线编号:" prop="pipeCode" v-if="form.id">
            <el-input
              v-model="form.pipeCode"
              placeholder="请输入管线编号"
              maxlength="40"
              disabled
              :style="labelWidthMax"
            ></el-input>
          </el-form-item>
          <el-form-item label="管道名称:" prop="pipeName">
            <el-input
            :disabled="isEdit? false: true"
              v-model="form.pipeName"
              placeholder="请输入管道名称"
              :style="labelWidthMax"
              maxlength="40"
            ></el-input>
          </el-form-item>
          <el-form-item label="管材:" prop="pipeType">
            <el-select
            :disabled="isEdit? false: true"
              v-model="form.pipeType"
              popper-class="gis-default"
              placeholder="请选择管材"
              :style="labelWidth"
              @change="pipeTypeChange"
            >
              <el-option
                v-for="item in pipeTypeList"
                :key="item.dictCode"
                :label="item.name"
                :value="item.dictCode"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="管材型号:" prop="pipeModel">
            <el-select
            :disabled="isEdit? false: true"
              v-model="form.pipeModel"
              popper-class="gis-default"
              placeholder="请选择管材型号"
              clearable
              :style="labelWidth"
              @change="diameterChge"
            >
              <el-option
                v-for="item in pipeModelList"
                :key="item.dictCode"
                :label="item.name"
                :value="item.dictCode"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="公称直径:" prop="diameter">
            <el-select
            :disabled="isEdit? false: true"
              v-model="form.diameter"
              popper-class="gis-default"
              placeholder="请选择公称直径"
              :style="labelWidth"
              @change="diameterChge"
            >
              <el-option
                v-for="item in diameterList"
                :key="item.code"
                :label="item.name"
                :value="item.code"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="实际外径(mm):" prop="outerDiameter">
            <el-input
            :disabled="isEdit? false: true"
              v-model="form.outerDiameter"
              maxlength="22"
              placeholder="请输入实际外径"
              :style="labelWidth"
            ></el-input>
          </el-form-item>
          <el-form-item label="壁厚(mm):" prop="thickness">
            <el-input
            :disabled="isEdit? false: true"
              v-model="form.thickness"
              placeholder="请输入壁厚"
              clearable
              :style="labelWidth"
              maxlength="22"
            >
            </el-input>
          </el-form-item>
          <el-form-item label="实际内径(mm):" prop="innerDiameter">
            <el-input
            :disabled="isEdit? false: true"
              v-model="form.innerDiameter"
              maxlength="22"
              placeholder="请输入实际内径"
              :style="labelWidth"
            ></el-input>
          </el-form-item>
          <el-form-item label="粗糙度:" prop="roughness">
            <el-input-number
            :disabled="isEdit? false: true"
              v-model="form.roughness"
              :precision="2"
              :step="0.01"
              :max="999999.99"
              :min="0"
              :style="labelWidth"
            ></el-input-number>
          </el-form-item>
          <el-form-item label="埋深:" prop="depth">
            <el-input
            :disabled="isEdit? false: true"
              v-model="form.depth"
              placeholder="请输入埋深"
              clearable
              :style="labelWidth"
              maxlength="22"
            >
            </el-input>
          </el-form-item>
          <el-form-item label="压力级别:" prop="pressureLevel">
            <el-select
            :disabled="isEdit? false: true"
              v-model="form.pressureLevel"
              popper-class="gis-default"
              :style="labelWidth"
              placeholder="请选择压力级别"
            >
              <el-option
                v-for="item in pressureLevelList"
                :key="item.dictCode"
                :label="item.name"
                :value="item.dictCode"
              >
              </el-option>
            </el-select>
          </el-form-item>
           <el-form-item label="设计压力:" >
            <el-input
            :disabled="isEdit? false: true"
              v-model="form.designPressure"
              placeholder="请输入设计压力"
              :style="labelWidth"
              maxlength="60"
            ></el-input>
          </el-form-item>
          <el-form-item label="运行压力:" >
            <el-input
            :disabled="isEdit? false: true"
              v-model="form.operationPressure"
              placeholder="请输入运行压力"
              :style="labelWidth"
              maxlength="60"
            ></el-input>
          </el-form-item>
          <el-form-item label="管道长度(m):" v-if="lineArr.length<2">
            <el-input
            disabled
              v-model="form.pipeLength"
              
              :style="labelWidth"
              placeholder="请输入管道长度"
            ></el-input>
          </el-form-item>
         
          <el-form-item label="建设日期:" prop="buildDate">
            <el-date-picker
            :disabled="isEdit? false: true"
              v-model="form.buildDate"
              type="date"
              :style="labelWidth"
              clearable="ture"
              :editable="false"
              popper-class="datePickerClass"
              value-format="yyyy-MM-dd"
              placeholder="建设日期"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item label="燃气介质:" prop="gasType">
            <el-select
            :disabled="isEdit? false: true"
              v-model="form.gasType"
              popper-class="gis-default"
              :style="labelWidth"
              placeholder="请选择燃气介质"
            >
              <el-option
                v-for="item in gasTypeList"
                :key="item.dictCode"
                :label="item.name"
                :value="item.dictCode"
              >
              </el-option>
            </el-select>
          </el-form-item>
         
          <el-form-item label="状态:" prop="pipeStatus">
            <el-select
            :disabled="isEdit? false: true"
              v-model="form.pipeStatus"
              popper-class="gis-default"
              :style="labelWidth"
              placeholder="请选择状态"
            >
              <el-option
                v-for="item in pipeStatusList"
                :key="item.dictCode"
                :label="item.name"
                :value="item.dictCode"
              >
              </el-option>
            </el-select>
          </el-form-item>
           <el-form-item label="敷设方式:" prop="buildType">
            <el-select
            :disabled="isEdit? false: true"
              v-model="form.buildType"
              popper-class="gis-default"
              :style="labelWidth"
              placeholder="请选择敷设方式"
            >
              <el-option
                v-for="item in buildTypeList"
                :key="item.dictCode"
                :label="item.name"
                :value="item.dictCode"
              >
              </el-option>
            </el-select>
          </el-form-item>
          
          <el-form-item label="确认状态:" prop="confirmStatusCode">
            <el-select
            :disabled="isEdit? false: true"
              v-model="form.confirmStatusCode"
              popper-class="gis-default"
              :style="labelWidth"
              placeholder="请选择确认状态"
            >
              <el-option
                v-for="item in confirmStatusList"
                :key="item.dictCode"
                :label="item.name"
                :value="item.dictCode"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="下次检测日期:" prop="nextMonitorDate">
             <el-date-picker
             :disabled="isEdit? false: true"
              v-model="form.nextMonitorDate"
              type="date"
              :style="labelWidth"
              :clearable="true"
              :editable="false"
              popper-class="datePickerClass"
              value-format="yyyy-MM-dd"
              placeholder="下次检测日期"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item label="所在道路:" prop="road">
            <el-input
            :disabled="isEdit? false: true"
              v-model="form.road"
              placeholder="请输入所在道路"
              :style="labelWidthMax"
              maxlength="60"
            ></el-input>
          </el-form-item>
           <el-form-item label="建设单位:">
            <el-input
            :disabled="isEdit? false: true"
              v-model="form.buildOrganization"
              placeholder="请输入建设单位"
              :style="labelWidthMax"
              maxlength="60"
            ></el-input>
          </el-form-item>
          <el-form-item label="设计单位:">
            <el-input
            :disabled="isEdit? false: true"
              v-model="form.designOrganization"
              placeholder="请输入设计单位"
              :style="labelWidthMax"
              maxlength="60"
            ></el-input>
          </el-form-item>
          
          <el-form-item label="备注:">
            <el-input
            :disabled="isEdit? false: true"
              v-model="form.remark"
              type="textarea"
              :rows="2"
              :style="labelWidthMax"
              maxlength="60"
              placeholder="请输入备注"
            ></el-input>
          </el-form-item>
        </el-form>
      </div>
      <div class="btn">
        <el-button size="mini" @click="close(1)">取消</el-button>
        <el-button v-if="isEdit" type="primary" size="mini" @click="update"
          >保存</el-button
        >
        <el-button v-else type="primary" size="mini" @click="eidtStatus()">编辑</el-button>
       
      </div>
    </el-dialog>
    <station-location ref="stationLocation" ></station-location>
  </div>
</template>

<script>
import pageMixins from "@/utils/pageMixins"
import { exportMethod } from '../../common/js/exportExcel'
import { pipeExportStandingBook, pipePageStandingBook} from "../../RequestPort/gisbook"
import { commonDict, serverMap } from '../Gis/utils/dict'
import { findDictTree, selectDictListByPid } from '../Gis/apis/commonType'
import { pipeFindById, pipeUpdate } from '../Gis/apis/tool'
import StationLocation from './stationLocation.vue'
import coverlayer from "../Gis/apis/coverlayer"
export default {
  name: 'PipeLineList',
  components: {
    StationLocation
  },
  mixins: [pageMixins],
  data() {
    return {
      titelName:'',
      pressureLevelList:serverMap['1006'],
      pipeTypeList:[],
      diameterList:[],
      diameterListTemp:[],
      searchDate:[],
      pageParams: {
        pipeName:'',
        pressureLevel:'',
        pipeType:'',
        diameter:'',
        current: 1,
        size: 100,
      }, //  分页查询参数
      total:null,
      tableData: [], //  表格数据
      form: {
        pipeType: '',
        pipeModel: '',
        diameter: '',
        pipeStatus: '',
        pipeName:'',
        pressureLevel:'',
        gasType:'',
        buildDate:'',
        pipeStatus:'',
        buildType:'',
        confirmStatusCode:'',
        nextMonitorDate:'',
        innerDiameter:'',
        outerDiameter:'',
        thickness:''
      },
      labelWidth: 'width:130px',
      labelWidthMax: 'width:385px',
      dialogVisible: false,
      buildTypeList: [],
      pipeTypeList: [],
      pipeModelList: [],
      diameterList: [],
      diameterData: [],
      gasTypeList: [],
      pipeStatusList: [],
      standardList: [],
      antisepticTypeList: [],
      pipeLengthTotal: null,
      lineArr: [],
      confirmStatusList:[],
      
      
      rules: {
        // roughness: [{ required: true, message: '请输入粗糙度', trigger: ['blur', 'change'] }],
        pipeName: [{ required: true, message: '请输入管道名称', trigger: 'blur' }],
        pipeType: [{ required: true, message: '请选择管材', trigger: 'change' }],
        // pipeModel: [{ required: true, message: '请选择管材型号', trigger: 'change' }],
        diameter: [{ required: true, message: '请选择公称直径', trigger: 'change' }],
        pressureLevel: [{ required: true, message: '请选择压力级别', trigger: 'change' }],
        gasType: [{ required: true, message: '请选择燃气介质', trigger: 'change' }],
        // buildDate: [{ required: true, message: '请选择建设日期', trigger: 'change' }],
        pipeStatus: [{ required: true, message: '请选择状态', trigger: 'change' }],
        buildType:[{ required: true, message: '请选择敷设方式', trigger: 'change' }],
        confirmStatusCode:[{ required: true, message: '请选择确认状态', trigger: 'change' }],
        // nextMonitorDate:[{ required: true, message: '下次检测日期', trigger: 'change' }],
        innerDiameter: [
          {
            pattern: /(^[1-9]\d{0,5}?(\.[0-9]{1,2})?$)|(^(0){1}$)|(^[0-9]\.[0-9]([0-9])?$)/,
            message: '数据格式不正确!'
          }
        ],
       outerDiameter: [
          {
            pattern: /(^[1-9]\d{0,5}?(\.[0-9]{1,2})?$)|(^(0){1}$)|(^[0-9]\.[0-9]([0-9])?$)/,
            message: '数据格式不正确!'
          }
        ],
        thickness: [
          {
            pattern: /(^[1-9]\d{0,5}?(\.[0-9]{1,2})?$)|(^(0){1}$)|(^[0-9]\.[0-9]([0-9])?$)/,
            message: '数据格式不正确!'
          }
        ],
        depth: [
          {
            pattern: /(^[1-9]\d{0,5}?(\.[0-9]{1,2})?$)|(^(0){1}$)|(^[0-9]\.[0-9]([0-9])?$)/,
            message: '数据格式不正确!'
          }
        ]
      },
      loadingD:true,
      isEdit:false,
      formOld:{},
      optionsList:[],
      props: {
        emitPath: false,
        label: 'name',
        value: 'id'
      },
    };
  },
  mounted() {
    let pipeName = this.$route.query.pipeName;
    if(pipeName){
      this.pageParams.pipeName = pipeName;
    }
    this.loadList(this.pageParams)
    this.getCodeNameList() 
  },
  methods: {
    exportFile(){
      pipeExportStandingBook(this.pageParams).then(res=>{
        exportMethod(res,'管段台账报表')
      })
    },
   async getCodeNameList(){
      let p = { sysType: 1}
      let res = await coverlayer.getFindTree(p)
      this.optionsList = res.data
      let that = this
      let code =
        commonDict[100023].code +
        ',' +
        commonDict[100024].code +
        ',' +
        commonDict[100028].code +
        ',' +
        commonDict[100029].code +
        ',' +
        commonDict[100004].code +
        ',' +
        commonDict[30011].code
        

      findDictTree({ dictCode: code }).then(res => {
        if (res.data) {
          let pipeTypeData = res.data[commonDict[100023].code]
          that.pipeTypeList = pipeTypeData
          that.pressureLevelList = serverMap[1006]
          that.gasTypeList = serverMap[1008]
          that.pipeStatusList = serverMap[1007]
          that.standardList = res.data[commonDict[100028].code]
          that.antisepticTypeList = res.data[commonDict[100029].code]
        }
      })
    },
    getCodeName(type, value) {
      let that = this
      let code =
        commonDict[100023].code +
        ',' +
        commonDict[100024].code +
        ',' +
        commonDict[100028].code +
        ',' +
        commonDict[100029].code +
        ',' +
        commonDict[100004].code +
        ',' +
        commonDict[30011].code
        

      findDictTree({ dictCode: code }).then(res => {
        if (res.data) {
          let pipeTypeData = res.data[commonDict[100023].code]
          that.pipeTypeList = pipeTypeData
          that.pressureLevelList = serverMap[1006]
          that.gasTypeList = serverMap[1008]
          that.pipeStatusList = serverMap[1007]
          that.standardList = res.data[commonDict[100028].code]
          that.antisepticTypeList = res.data[commonDict[100029].code]
          that.buildTypeList = res.data[commonDict[100004].code]
          that.confirmStatusList = res.data[commonDict[30011].code]
          
            this.pipeTypeChange(value, type)
          
            
          
        }
      })
    },
    eidtStatus(){
      this.isEdit = true;
      this.formOld = this.form
    },
     getRealBore() {
      let list = this.diameterList.find(el => el.code == this.form.diameter)
      if (list && list.value1) {
        let all = list.value1
        let arr = []
        let realBore = ''
       
          arr = all.split('*')
          realBore = arr[0] - arr[1] * 2
          this.$set(this.form, 'thickness', arr[1])
          this.$set(this.form, 'innerDiameter', realBore)
          this.$set(this.form, 'outerDiameter', arr[0])
      }
    },
    diameterChge(val) {
      this.$set(this.form, 'thickness', null)
      this.$set(this.form, 'innerDiameter', null)
      this.$set(this.form, 'outerDiameter', null)
      this.getRealBore()
    },
    close(flag){
      if(this.isEdit&flag==1&&this.form.id){
        this.isEdit = false
        this.form = this.formOld
      }else{
        this.dialogVisible = false
        this.form = {}
      }
    },
    update() {
      let that = this
      that.$refs.form.validate(valid => {
        if (valid) {
          pipeUpdate(that.form)
            .then(res => {
              if (res.code == 200) {
                that.$message.success(res.msg)
                this.getDetail({id:this.form.id})
                this.loadList(this.pageParams)
              } else {
                that.$message.error(res.msg)
              }
            })
            // .catch(err => {
            //   that.$message.error(err)
            // })
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    search() {
      if(this.searchDate && this.searchDate.length > 0) {
      this.pageParams.startTime = this.searchDate[0];
      this.pageParams.endTime = this.searchDate[1];
      } else {
        delete this.pageParams.startTime
        delete this.pageParams.endTime
      }
      this.pageParams.current = 1;
      this.loadList(this.pageParams);
    },
    restSearch() {
      this.diameterList = []
      this.searchDate = []
      this.pageParams = {
        pipeName:'',
        pressureLevel:'',
        pipeType:'',
        diameter:'',
        current: 1,
        size: 100,
      }
      this.loadList(this.pageParams)
    },
    loadList(obj){
      pipePageStandingBook(obj).then(res=>{
        this.tableData = res.data.records
        this.total = res.data.total
      })
    },

    pipeTypeChange(value) {
      this.pageParams.diameter = ''
      this.diameterList = []
    
      for (let item of this.pipeTypeList) {
        if (item.dictCode == value) {
          this.pipeModelList = item.children
            this.setDiameterList(item.linkCode)
          break
        }
      }
    },
    setDiameterList(linkCode) {
      selectDictListByPid({pid:linkCode}).then(res=>{
        this.diameterList = res.data
      })
    },
    handleCurrentChange(current) {
      this.pageParams.current = current;
      this.loadList(this.pageParams);
    },
    handleSizeChange(val) {
      this.pageParams.size = val;
      this.handleCurrentChange(1);
    },
    getDetail(row){
      
      
      this.isEdit = false;
      this.dialogVisible = true
       pipeFindById({ id: row.id }).then(res => {
        this.getCodeName('edit', res.data.pipeType)
        this.form = res.data
      })
    },
    setPosition(row){
      let data = {}
      let lonlat = 'POINT('+row.lineArray[0][0]+' '+row.lineArray[0][1]+')'
      pipeFindById({ id: row.id }).then(res => {
        data = res.data;
        data.showType = 'line'
        data.title="管道信息"
        
         this.$refs.stationLocation.openDialog(data,lonlat)
      })
    }
  },
};
</script>

<style lang="scss" scoped>
.searchBox {
  .searchBoxForm {
    display: flex;
    align-items: center;
  }
}
.tableTool{    
  height: 25px;
}
.searchItem {
  margin-bottom: 1%;
  margin-left: 1%;
}
.label {
  color: #909399;
}
.content-item {
  color: #444444;
}
.SearchBar {
  border-radius: 5px;
  margin: 10px;
  padding-top: 1%;
  background-color: #ffffff;
}
.all {
  background-color:#f3f4f8;height:auto
}
.content {
  background: #f3f4f8;
  width: 100%;
  border-radius: 6px;
  overflow:auto;
  height: calc(100vh - 120px);
}
.search{
  width: 215px;
}
.contain{
  height: 690px;
}
.total{
  margin-left: 15px;
  margin-top: 10px;
}
.table{
  overflow:auto;
  height: 520px;
}
.btn{text-align: right;}
</style>